import React from "react"
import { Container, Row, Col, Card, CardDeck } from "react-bootstrap"

const Leadership = ({ pageInfo }) => {

	let leaders = pageInfo.leaders.map((leader) =>
		<Col xs="12" md="4" className={'leader'} key={leader.key}  id={leader.key}>
			<img src={leader.image} alt={leader.name} className={'rounded-circle mx-auto d-block img-fluid'} />
			<h3>{leader.name}</h3>
			<h4>{leader.title}</h4>
			<p>{leader.bio}</p>
		</Col>
	)


	return (
		<section className={'leadership'}>
			<Container>
				<Row>
					<Col>
						<h2>Management Team</h2>
						<p className="pb-2">{pageInfo.content}</p>
					</Col>
				</Row>
				<Row>
					{leaders}
				</Row>
			</Container>
		</section>
	)

}

export default Leadership
