import React from "react"
import { Container, Row, Col, Card, CardDeck } from "react-bootstrap"

const Board = ({ pageInfo }) => {

	let members = pageInfo.members.map((member) =>
		<Row key={member.key}  id={member.key}>
			<Col>
				<div className={'member'}>
					<div className="contentBlock">
						<div className="clickBlock">
							<div className="" data-toggle="collapse" data-target={'#board-content-' + member.key} >
								<a className="btn btn-link btn-sm">
									<i className="fal fa-fw fa-plus-square fa-2x d-inline mt-1 mr-2"></i>
									<i className="fal fa-fw fa-minus-square fa-2x d-none mt-1 mr-2"></i>
								</a>
								<h2 className="d-inline pb-1 title">
									{ member.name }
								</h2>
								<h3 className="subtitle">
									{member.title}
								</h3>
							</div>
						</div>
						<div id={'board-content-' + member.key} className="content collapse" dangerouslySetInnerHTML={{__html: member.bio }}></div>
					</div>
				</div>
			</Col>
		</Row>
	)




	return (
		<section className={'board'}>
			<Container>
				<Row>
					<Col>
						<h2>Board of Directors</h2>
					</Col>
				</Row>
				<Row>
					<Col>
						<p>{pageInfo.content}</p>
					</Col>
				</Row>
				<div className="members-list">
					{members}
				</div>
			</Container>
		</section>
	)

}

export default Board
